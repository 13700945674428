import React, { useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField, Box } from "@mui/material";

const DateRangePicker = ({
    onChange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {

    const handleDateChange = useCallback(
        (newStart, newEnd) => {
            setStartDate(newStart);
            setEndDate(newEnd);
            onChange([newStart, newEnd]);
        },
        [onChange]
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "white",
                width: "80%",
                margin: "0 auto",
            }}
        >
            <DatePicker
                label="From"
                value={startDate}
                onChange={(newStart) =>
                    handleDateChange(newStart, newStart && endDate < newStart ? null : endDate)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": { color: "white !important" },
                            "& .MuiSvgIcon-root": { color: "white !important" },
                            "& .MuiFormLabel-root": { color: "white !important" }
                        }}
                    />
                )}
            />
            <DatePicker
                label="To"
                value={endDate}
                minDate={startDate}
                onChange={(newEnd) => handleDateChange(startDate, newEnd)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": { color: "white !important" },
                            "& .MuiSvgIcon-root": { color: "white !important" },
                            "& .MuiFormLabel-root": { color: "white !important" }
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default DateRangePicker;
