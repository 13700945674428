import {
	Badge,
	Grid,
	FormControl,
	Select,
	MenuItem,
	Chip,
	Typography,
} from "@mui/material";
import Search from "./Search.js";
import { useState, useEffect } from "react";
import { publicationStates, publicationTypes } from "../utils/publication-constants.js";
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import isFuzzyMatch from "../utils/is-fuzzy-match.js";
import { getTeamMembers } from "../api/index.js";
import { useSnackbar } from "../utils/index.js";
import DateRangePicker from "./DateRangePicker.js";
import FilterListIcon from '@mui/icons-material/FilterList';

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "white", // Set the desired color for the select
		fill: "white",
		"&:before": {
			borderColor: "white", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "white", // Set the desired color for the select's border when focused
		},
	},
	clearFiltersChip: {
		color: theme.palette.third.main,
		borderColor: theme.palette.third.main,
		border: "1px solid",
		"& .MuiChip-label": {
			color: theme.palette.third.main,
		},
		fontSize: "0.9rem",
		"&:hover": {
			borderColor: theme.palette.third.dark,
			color: theme.palette.third.dark,
			"& .MuiChip-label": {
				color: theme.palette.third.dark,
			},
		},
	},
	memberSelect: {
		color: "white",
		fill: "white",
		width: '130px',
		"&:before": {
			borderColor: "white",
		},
		"&:after": {
			borderColor: "white",
		},
	},
	supervisorLabel: {
		color: "white",
		fill: "white",
		marginRight: "1rem",
		"&:before": {
			borderColor: "white",
		},
		"&:after": {
			borderColor: "white",
		},
	},
	archiveChipShow: {
		backgroundColor: theme.palette.info.main,
		"& .MuiChip-label": {
			color: "white",
		},
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	archiveChipHide: {
		backgroundColor: theme.palette.error.main,
		"& .MuiChip-label": {
			color: "white",
			// backgroundColor: theme.palette.error.dark,
		},
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
}));

const PublicationFilters = ({
	teams,
	publications,
	setFilteredPublications,
	showTeamFilter,
	showStatesFilter,
	showTypesFilter,
	setFilterType, // Required for metrics component
	showMembersFilter, // Required for members select element
	teamId, // Required for members select element
	showSupervisorsFilter, // Required for supervisors select element
	showDateRangeFilter, //Required for DateRange filter
}) => {
	const classes = useStyles();
	const { error } = useSnackbar();
	const [publicationsSearchFilter, setPublicationsSearchFilter] = useState("");
	const [stableSearch, setStableSearch] = useState("");
	const [archivedPublications, setArchivedPublications] = useState(0);
	const [filterExpanded, setFilterExpanded] = useState(false);
	const [publicationType, setPublicationType] = useState("All types");
	const [publicationState, setPublicationState] = useState("All states");
	const [publicationTeam, setPublicationTeam] = useState("All teams");
	const [publicationMember, setPublicationMember] = useState("All members");
	const [publicationSupervisor, setPublicationSupervisor] = useState("All members");
	const [showArchived, setShowArchived] = useState(false);
	const [teamMembers, setTeamMembers] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const fetchMembers = async (teamId) => {
		try {
			const { success: scs, ordered: ord } = await getTeamMembers(teamId);
			if (scs) {
				const members = ord.members.map((member) => ({ value: member.fullname, text: member.fullname }));
				members.unshift({
					value: "All members",
					text: "All members",
				});
				setTeamMembers(members);
			} else {
				error();
			}
		} catch (error_) {
			console.log(error_);
			error();
		}
	};

	useEffect(() => {
		// console.log("Changed filters!");
		// console.log("before:", publications.length);
		console.log("Us", publications)
		let tmpPubs = publications.filter((us) =>
			isFuzzyMatch(us.title, stableSearch) ||
			us.orderedAuthorsNames.some((author) => isFuzzyMatch(author, stableSearch))
		);
		tmpPubs = tmpPubs.filter((us) => (publicationTeam === "All teams" ? true : us.teamName === publicationTeam));
		tmpPubs = tmpPubs.filter((us) => (publicationType === "All types" ? true : us.publicationType === publicationType));
		tmpPubs = tmpPubs.filter((us) => (publicationState === "All states" ? true : us.state.state === publicationState));
		tmpPubs = tmpPubs.filter((us) => (publicationMember === "All members" ? true : us.orderedAuthorsNames.includes(publicationMember)));
		tmpPubs = tmpPubs.filter((us) => (publicationSupervisor === "All members"
			? true
			: us.orderedSupervisorsNames.some((supervisor) => supervisor.name === publicationSupervisor)));
		tmpPubs = tmpPubs.filter((us) => (showArchived ? us.isArchived : !us.isArchived));
		if (startDate && endDate) {
			tmpPubs = tmpPubs.filter((us) => {
				const pubDate = new Date(us?.publicationDate);
				return pubDate >= new Date(startDate) && pubDate <= new Date(endDate);
			});
		}
		// console.log("after:", tmpPubs.length);
		setFilteredPublications(tmpPubs);
		setArchivedPublications(publications.filter((us) => us.isArchived).length);
		if (setFilterType) {
			setFilterType(publicationType);
		}

		if (teamId) {
			fetchMembers(teamId);
		}
	}, [
		stableSearch, publications, publicationTeam, publicationType, publicationState,
		publicationMember, publicationSupervisor, showArchived, startDate, endDate]);

	// Create handle to update publicationsSearchFilter when the user stops typing for 2 seconds
	let typingTimer;
	const doneTypingInterval = 2000;
	const handleSearchChange = (event) => {
		setPublicationsSearchFilter(event.target.value);
		clearTimeout(typingTimer);
		typingTimer = setTimeout(() => {
			setStableSearch(event.target.value);
		}, doneTypingInterval);
	};


	const dateRangePickerOnChange = () => {
		console.log("Ok")
	}

	return (
		<Grid
			container
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="flex-end"
			sx={{
				mb: 1,
			}}
		>
			<Grid
				item
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="flex-end"
			>
				<Chip
					label="Filters"
					icon={<FilterListIcon />}
					className={classes.clearFiltersChip}
					onClick={() => setFilterExpanded(!filterExpanded)}
					sx={{ mr: 1 }}
				/>
				<Search value={publicationsSearchFilter} width="300px" onChange={(event) => handleSearchChange(event)} />
				<Badge badgeContent={archivedPublications} color="warning">
					<Chip
						label={showArchived ? "Hide archived" : "Show archived"}
						size="medium"
						className={showArchived ? classes.archiveChipHide : classes.archiveChipShow}
						sx={{ ml: 0.5, cursor: "pointer" }}
						onClick={() => {
							setShowArchived(!showArchived);
						}}
					/>
				</Badge>
			</Grid>
			{filterExpanded && (
				<Grid
					item
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
					sx={{
						mt: "1rem",
						border: "2px solid",
						borderColor: "primary.main",
						borderRadius: "8px",
					}}
				>
					{showDateRangeFilter && (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
							<DateRangePicker
								onChange={dateRangePickerOnChange}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate} />
						</FormControl>
					)}
					{showTeamFilter && (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
							<Select
								className={classes.select}
								value={publicationTeam}
								mr={1}
								onChange={(event) => {
									setPublicationTeam(event.target.value);
								}}
							>
								{
									teams.map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					)}
					{showTypesFilter && (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
							<Select
								className={classes.select}
								value={publicationType}
								mr={1}
								onChange={(event) => {
									setPublicationType(event.target.value);
								}}
							>
								{
									publicationTypes.map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					)}
					{showStatesFilter && (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
							<Select
								className={classes.select}
								value={publicationState}
								mr={1}
								onChange={(event) => {
									setPublicationState(event.target.value);
								}}
							>
								{
									publicationStates.map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					)}
					{showMembersFilter && (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
							<Select
								className={classes.memberSelect}
								value={publicationMember}
								mr={1}
								onChange={(event) => {
									setPublicationMember(event.target.value);
								}}
							>
								{
									teamMembers.map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					)}
					{showSupervisorsFilter && (
						<Grid container flexDirection="row" alignItems="center" sx={{ maxWidth: 280, marginLeft: "1rem" }}>
							<Typography className={classes.supervisorLabel}>
								{"Supervisor :"}
							</Typography>
							<FormControl variant="standard" sx={{ minWidth: 130 }}>
								<Select
									className={classes.memberSelect}
									value={publicationSupervisor}
									onChange={(event) => {
										setPublicationSupervisor(event.target.value);
									}}
								>
									{
										teamMembers.map((e, ind) => (
											<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</Grid>
					)}
					{(showStatesFilter === true || showTeamFilter === true || showTypesFilter === true || showMembersFilter === true) && (
						<Chip
							label="Clear filters"
							className={classes.clearFiltersChip}
							deleteIcon={<ClearIcon color="inherit" fontSize="small" />}
							sx={{
								mr: 1,
							}}
							onClick={() => {
								setPublicationState("All states");
								setPublicationType("All types");
								setPublicationTeam("All teams");
								setStableSearch("");
								setPublicationsSearchFilter("");
								setPublicationMember("All members");
								setPublicationSupervisor("All members");
								setStartDate(null);
								setEndDate(null);
							}}
							onDelete={() => {
								setPublicationState("All states");
								setPublicationType("All types");
								setPublicationTeam("All teams");
								setStableSearch("");
								setPublicationsSearchFilter("");
								setPublicationMember("All members");
								setPublicationSupervisor("All members");
								setStartDate(null);
								setEndDate(null);
							}}
						/>
					)}

				</Grid>
			)}
		</Grid>
	);
};

export default PublicationFilters;
